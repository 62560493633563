import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";

import IntersectionBox from "../animations/IntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import { zoomInWithDelay, zoomOutWithDelay } from "../animations/variants";
import GetQuoteSection from "../components/GetQuoteSection";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { FaCheckCircle } from "react-icons/fa";
import RequestCallback from "../components/RequestCallback";

function QualityAssurance() {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        qualityAssurance {
          quality_title_description {
            description_text
            heading_text
          }
          quality_process_types {
            heading
            description
            graphic {
              image {
                url
                alternativeText
              }
            }
          }
          support_like_no_other {
            heading
            description
            graphic {
              image {
                url
                alternativeText
              }
            }
          }
          certification_icon {
            logo_title
            logo_image {
              image {
                url
                alternativeText
              }
            }
          }
          seo {
            meta_description
            meta_keywords
            title
          }
        }
      }
    }
  `);

  const node = data.strapi.qualityAssurance;

  return (
    <Layout>
      <SEO
        title={node.seo.title}
        keywords={node.seo.meta_keywords}
        description={node.seo.meta_description}
      />
      <div className="content container-fluid">
        <div className="qual-std">
          <div className="qual-process ">
            <p className="qual-head">
              <TrailingParagraph
                paragraph={node.quality_title_description.heading_text}
              />
            </p>
            <p className="qual-desc">
              <TrailingParagraph
                paragraph={node.quality_title_description.description_text}
              />
            </p>
            <IntersectionBox variants={zoomOutWithDelay(0.1)} threshold={0.1}>
              <div className="qual-img">
                {/* <img src={"/images/mauf_machine.png"} />
                 */}
                <video
                  muted
                  playsInline
                  autoPlay
                  loop="true"
                  id="quality-background-video"
                  className="quality-background-video"
                  poster={"/images/poster/CNC.jpg"}
                  preload="auto"
                  src={"/videos/cmm.mp4"}
                  width="100%"
                ></video>
              </div>
            </IntersectionBox>
          </div>
          <div className="cert-section container-fluid">
            <div className="row">
              <div className="cert-info col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <p className="cert-head">
                  <TrailingParagraph paragraph="Certifications" />
                </p>
                <p className="cert-desc">
                  <TrailingParagraph
                    paragraph="Our facilities are well equipped with cutting edge
                  manufacturing technology and have years of experience in
                  manufacturing production."
                  />
                  <br />
                  <br />
                  <TrailingParagraph
                    paragraph="Our diverse manufacturing partners have certificates you
                  demand for your applications."
                  />
                </p>
              </div>
              <div className="cert-list col-lg-6 col-md-6 col-sm-6 col-xs-12 container-fluid">
                <div className="row cert-icon-row">
                  {node.certification_icon.map((cert, icon) => (
                    <div className="column col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <IntersectionBox>
                        <div className="cert-icon">
                          <figure>
                            <img
                              src={cert.logo_image.image.url}
                              alt={cert.logo_title}
                            />
                          </figure>
                        </div>
                        <p className="cert-icon-desc"> {cert.logo_title} </p>
                      </IntersectionBox>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="insp-process container-fluid">
          <p className="insp-head">
            <TrailingParagraph paragraph="Quality Inspection process" />
          </p>
          <p className="insp-desc">
            <TrailingParagraph
              paragraph="Our facilities have a comprehensive set of plans and system of
            procedures that guides manufacturing tasks and quality inspection on
            every production run"
            />
          </p>
          <div className="row insp-method">
            {node.quality_process_types.map((qa, index) => (
              <div className="column col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <IntersectionBox variants={zoomInWithDelay(0.1)}>
                  <div className="insp-method-icon">
                    <figure>
                      <img src={qa.graphic.image.url} alt={qa.heading} />
                    </figure>
                  </div>
                  <p className="insp-method-head">
                    <TrailingParagraph paragraph={qa.heading} />
                  </p>
                  <p className="insp-method-desc">
                    <TrailingParagraph paragraph={qa.description} />
                  </p>
                </IntersectionBox>
              </div>
            ))}
          </div>
        </div>

        <div className="qual-insp">
          <div className="row">
            <div className="specific-insp col-lg-6 col-md-6 col-sm-12">
              <p className="spec-head">
                <TrailingParagraph paragraph="Specific Inspections" />
              </p>
              <p className="spec-desc">
                <TrailingParagraph
                  paragraph="Along with Custiv’s mandatory inspection check, you can have
                your specific inspections also done."
                />
                <br />
                <br />
                <TrailingParagraph paragraph="Here are some of the inspections we offer:" />
              </p>
              <IntersectionBox>
                <p className="insp-list">
                  <FaCheckCircle /> Height Gauge
                  <span> (2D and 3D) </span>
                </p>
              </IntersectionBox>
              <IntersectionBox>
                <p className="insp-list">
                  <FaCheckCircle /> CMM Inspection reports
                </p>
              </IntersectionBox>
              <IntersectionBox>
                <p className="insp-list">
                  <FaCheckCircle /> Manual inspection + dimensional report{" "}
                  &nbsp; (Vernier, Gauges)
                </p>
              </IntersectionBox>
              <IntersectionBox>
                <p className="insp-list">
                  <FaCheckCircle /> Profile Projectors
                </p>
              </IntersectionBox>
              <IntersectionBox>
                <p className="insp-list">
                  <FaCheckCircle /> Custom inspection sheets
                </p>
              </IntersectionBox>
              <IntersectionBox>
                <p className="insp-list">
                  <FaCheckCircle /> First Article inspection
                  <br />
                  &nbsp; (FAI in accordance with standards)
                </p>
              </IntersectionBox>
            </div>
            <div className="specific-insp col-lg-6 col-md-6 col-sm-12">
              <p className="spec-head">
                <TrailingParagraph paragraph="Quality inspection of injection moulded components" />
              </p>
              <p className="spec-desc">
                <TrailingParagraph
                  paragraph="Apart from the general dimension inspection of the component. Visual Inspection of Injection moulded
                components to identify and rectify the following defects
                specific to moulded components"
                />
              </p>
              <div className="row">
                <div className="column">
                  <IntersectionBox>
                    <p className="insp-list">
                      <FaCheckCircle /> Sink Marks
                    </p>
                  </IntersectionBox>
                  <IntersectionBox>
                    <p className="insp-list">
                      <FaCheckCircle /> Short Shots
                    </p>
                  </IntersectionBox>
                </div>
                <div className="column">
                  <IntersectionBox>
                    <p className="insp-list">
                      <FaCheckCircle /> Burn Marks
                    </p>
                  </IntersectionBox>
                  <IntersectionBox>
                    <p className="insp-list">
                      <FaCheckCircle /> Flsh Marks
                    </p>
                  </IntersectionBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="support-callback">
          <div className="support-team row">
            <div className="support-info col-lg-7 col-md-7 col-sm-12 col-xs-12">
              <p className="support-head">
                <TrailingParagraph
                  paragraph={node.support_like_no_other.heading}
                />
              </p>
              <p className="support-desc">
                <TrailingParagraph
                  paragraph={node.support_like_no_other.description}
                />
              </p>

              <IntersectionBox variants={zoomInWithDelay(0.5)}>
                <Link to="/contact-us">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="clippy_button contact-button"
                  >
                    Contact Us
                  </motion.button>
                </Link>
              </IntersectionBox>
            </div>
            <div className="support-illus col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <figure>
                <IntersectionBox>
                  <img
                    src={node.support_like_no_other.graphic[0].image.url}
                    alt="Custiv Support"
                  />
                </IntersectionBox>
              </figure>
            </div>
          </div>
        </div>
        <RequestCallback />
        <GetQuoteSection />
      </div>
    </Layout>
  );
}

export default QualityAssurance;
